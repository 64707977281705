.react-flow__handle-connecting {
    background-color: red !important;
}

.react-flow__handle-connecting.react-flow__handle-valid {
    background-color: green !important;
}

.react-flow__handle {
    border-radius: 2px !important;
    height: 12px !important;
    width: 12px !important;
}