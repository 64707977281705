svg {
    font: 12px sans-serif;
    text-anchor: middle;
}

.cor-rect {
    stroke: lightgray;
    fill: none;
}

.y.axis path {
    fill: none;
    stroke: none;
}
